import { combineReducers } from 'redux';
import { enableMapSet } from 'immer';
import reservationReducer from './reservation';
import filterReducer from './filter';
import myListReducer from './myList';
import noticeReducer from './notice';
import exhibitionReducer from './exhibition';
import campingLogReducer from './campingLog';
import loadingReducer from './loading';
import campReducer from './camp';
import zoneReducer from './zone';
import calendarReducer from './calendar';
import searchReducer from './search';
import searchGeoReducer from './searchGeo';
import siteReducer from './site';
import authReducer from './auth';
import registerReducer from './register';
import boardReducer from './board';
import commentsReducer from './comments';
import initReducer from './init';
import loginReducer from './login';
import bannerReducer from './banner';
import bannerListReducer from './bannerList';
import alarmsReducer from './alarms';
import myInfoReducer from './myInfo';
import camperReducer from './camper';
import openSchedulesReducer from './openSchedules';
import couponReducer from './coupon';
import vacancyAlarmsReducer from './vacancyAlarms';
import cancelReducer from './cancel';
import communityBlockReducer from './communityBlock';
import bestPhotosReducer from './bestPhotos';
import campAnnouncesReducer from './campAnnounces';
import zoneReservationStatusReducer from './zoneReservationStatus';
import paymentReducer from './payment';
import myReservationsReducer from './myReservations';
import articlesReducer from './articles';
import serviceInventoryReducer from './serviceInventory';
import appPushReducer from './appPush';
import productPickReducer from './productPick';
import productReducer from './product';
import categoryReducer from './category';
import myItemsReducer from './myItems';
import withdrawReducer from './withdraw';
import productQnaReducer from './productQna';
import toastReducer from './toast';
import productReviewsReducer from './productReviews';
import camperItemsReducer from './camperItems';
import productExhibitionReducer from './productExhibition';
import productMainCategoryReducer from './productMainCategory';
import productSearchReducer from './productSearch';
import autoCompleteReducer from './autoComplete';
import realtimeReservationReducer from './realtimeReservation';
import userReducer from './user';
import bookmarkReducer from './bookmark';
import eventReducer from './event';
import myCarNumberReducer from './myCarNumber';
import myPetsReducer from './myPets';
import hostsAndSuppliersReducer from './hostsAndSuppliers';
import selfAuthenticationReducer from './selfAuthentication';
import mainReducer from './main';
import v2CouponReducer from './v2Coupon';
import relatedProductReducer from './relatedProduct';
import algoliaSearchReducer from './algoliaSearch';
import algoliaRecommendReducer from './algoliaRecommend';
import channelTalkServiceReducer from './channerTalkService';
import storeReducer from './store';
import shopbyOrderReducer from './shopbyOrder';
import shopbyPointReducer from './shopbyPoint';
import shopbyClaimReducer from './shopbyClaim';
import shopbyUserAddressReducer from './shopbyUserAddress';
import shopbyReviewReducer from './shopbyReview';
import shopbySearchZipCode from './shopbySearchZipCode';
import shopbyMyInquiryReducer from './shopbyMyInquiry';
import shopbyMyReviewListReducer from './shopbyMyReviewList';
import shopbyItemExhibitionReducer from './shopbyItemExhibition';
import commonReducer from './common';
import dynamicBanners from './dynamicBanners';
import shopbyBestReviewReducer from './shopbyBestReview';
import shopbyCategoryReducer from './shopbyCategory';
import shopbyProductSearchReducer from '../shopby/shopbyProductSearchReducer';
import notionReducer from '../notion/reducer';
import curationListReducer from '../curations/reducer';
import socialLoginReducer from './socialLogin';
import socialLoginSelfAuthenticationReducer from './socialLoginSelfAuthentication';
import feedReducer from './feed';
import featureFlagsRefucer from './featureFlags';
import siteExhibitionReducer from '../siteExhibition/reducer';
import campExhibitionReducer from './campExhibition';
import campaignReducer from './campaign';

enableMapSet();

const rootReducer = combineReducers({
  alarmsReducer,
  appPushReducer,
  articlesReducer,
  authReducer,
  autoCompleteReducer,
  bannerReducer,
  bannerListReducer,
  bestPhotosReducer,
  boardReducer,
  bookmarkReducer,
  calendarReducer,
  campReducer,
  campAnnouncesReducer,
  camperReducer,
  camperItemsReducer,
  campingLogReducer,
  cancelReducer,
  categoryReducer,
  commentsReducer,
  communityBlockReducer,
  couponReducer,
  eventReducer,
  exhibitionReducer,
  filterReducer,
  initReducer,
  loadingReducer,
  loginReducer,
  myPetsReducer,
  myCarNumberReducer,
  myInfoReducer,
  myItemsReducer,
  myListReducer,
  myReservationsReducer,
  noticeReducer,
  openSchedulesReducer,
  paymentReducer,
  productReducer,
  productExhibitionReducer,
  productMainCategoryReducer,
  productPickReducer,
  productQnaReducer,
  productReviewsReducer,
  productSearchReducer,
  realtimeReservationReducer,
  registerReducer,
  reservationReducer,
  searchReducer,
  searchGeoReducer,
  serviceInventoryReducer,
  siteReducer,
  toastReducer,
  userReducer,
  vacancyAlarmsReducer,
  withdrawReducer,
  zoneReducer,
  zoneReservationStatusReducer,
  hostsAndSuppliersReducer,
  selfAuthenticationReducer,
  mainReducer,
  v2CouponReducer,
  relatedProductReducer,
  algoliaSearchReducer,
  algoliaRecommendReducer,
  channelTalkServiceReducer,
  storeReducer,
  shopbyOrderReducer,
  shopbyPointReducer,
  shopbyClaimReducer,
  shopbyUserAddressReducer,
  shopbyReviewReducer,
  shopbySearchZipCode,
  shopbyMyInquiryReducer,
  shopbyMyReviewListReducer,
  shopbyItemExhibitionReducer,
  commonReducer,
  dynamicBanners,
  shopbyBestReviewReducer,
  shopbyCategoryReducer,
  shopbyProductSearchReducer,
  notionReducer,
  curationListReducer,
  socialLoginReducer,
  socialLoginSelfAuthenticationReducer,
  feedReducer,
  featureFlagsRefucer,
  siteExhibitionReducer,
  campExhibitionReducer,
  campaignReducer,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
