import { put } from 'redux-saga/effects';
import { isEmpty } from 'lodash';
import { setReservationState } from 'store/reducers/reservation';
import { setZoneState } from 'store/reducers/zone';

function* setReservationInfoState() {
  const reservationStateInfo = JSON.parse(
    localStorage.getItem('reservationStateInfo') || 'null',
  );
  const zoneStateInfo = JSON.parse(
    localStorage.getItem('zoneStateInfo') || 'null',
  );

  if (!isEmpty(reservationStateInfo)) {
    yield put(setReservationState(reservationStateInfo));
  }

  if (!isEmpty(zoneStateInfo)) {
    yield put(setZoneState(zoneStateInfo));
  }

  yield localStorage.removeItem('reservationStateInfo');
  yield localStorage.removeItem('zoneStateInfo');
}

export default setReservationInfoState;
