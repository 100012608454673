import { AxiosError } from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CampState } from 'store/types';
import { ICamp, ICampDetail } from '@types';

const initialState: CampState = {
  mainNewCamps: [] as ICamp[],
  camp: {} as ICampDetail,
  error: null,
  scrollTop: 0,
};

export const campSlice = createSlice({
  name: 'camp',
  initialState,
  reducers: {
    getNewCampListRequest: (
      state,
      action: PayloadAction<{
        limit: number;
        sort: string;
      }>,
    ) => {
      state.mainNewCamps = [] as ICamp[];
      state.error = null;
    },
    getNewCampListSuccess: (state, action: PayloadAction<ICamp[]>) => {
      state.mainNewCamps = action.payload;
      state.error = null;
    },
    getNewCampListFailure: (state, action: PayloadAction<Error | string>) => {
      state.mainNewCamps = [] as ICamp[];
      state.error = action.payload;
    },
    getCampRequest: (state, action: PayloadAction<{ id: string }>) => {
      state.camp = {} as ICampDetail;
      state.error = null;
    },
    getCampSuccess: (state, action: PayloadAction<ICampDetail>) => {
      state.camp = action.payload;
      state.error = null;
    },
    getCampFailure: (state, action: PayloadAction<AxiosError>) => {
      state.camp = {} as ICampDetail;
      state.error = action.payload;
    },
    setCampIsBookmarked: (state, action: PayloadAction<boolean>) => {
      state.camp.isBookmarked = action.payload;
    },
    setScrollTop: (state, action: PayloadAction<number>) => {
      state.scrollTop = action.payload;
    },
  },
});

export const {
  getNewCampListRequest,
  getNewCampListSuccess,
  getNewCampListFailure,
  getCampRequest,
  getCampSuccess,
  getCampFailure,
  setCampIsBookmarked,
  setScrollTop,
} = campSlice.actions;

export default campSlice.reducer;
